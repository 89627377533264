import React, { useState, useEffect, useCallback } from 'react';
import { useLocation } from 'react-router-dom';
import axios from 'axios';
import Navbar from '../Navbar/Navbar';
import Footer from '../Footer/Footer';
import UserStats from '../UserStats/UserStats';
import ResultModal from '../ResultModal/ResultModal';
import DefinitionModal from '../DefinitionModal/DefinitionModal';
import NW_IntroductionModal from '../NW_IntroductionModal/NW_IntroductionModal'; 
import { FaVolumeUp, FaMicrophone } from 'react-icons/fa';
import './GamePage.css';
import '../../App.css';

const GamePage = () => {
  const location = useLocation();
  const { userName } = location.state || { userName: 'Player' };
  const [word, setWord] = useState('');
  const [nextWord, setNextWord] = useState('');
  const [error, setError] = useState(null);
  const [usedWords, setUsedWords] = useState([]);
  const [gameInProgress, setGameInProgress] = useState(true);
  const [wordSubmitted, setWordSubmitted] = useState(false);
  const [timeLeft, setTimeLeft] = useState(30);
  const [gameStarted, setGameStarted] = useState(false);
  const [wordsEntered, setWordsEntered] = useState(0);
  const [record, setRecord] = useState(() => {
    const savedRecord = localStorage.getItem('wordGameRecord');
    return savedRecord ? JSON.parse(savedRecord) : 0;
  });
  const [positionUp, setPositionUp] = useState(false);
  const [showResultButton, setShowResultButton] = useState(false);
  const [showResultModal, setShowResultModal] = useState(false); // New state for result modal visibility
  const [showModal, setShowModal] = useState(true);  // Show the modal initially
  const [resultWords, setResultWords] = useState([]);
  const [showDefinitionModal, setShowDefinitionModal] = useState(false);
  const [definition, setDefinition] = useState('');
  const [englishDefinition, setEnglishDefinition] = useState('');
  const [vietnameseDefinition, setVietnameseDefinition] = useState('');
  const [selectedWord, setSelectedWord] = useState('');
  const [gameOver, setGameOver] = useState(false);
  const [language, setLanguage] = useState('en');

  const updateRecord = useCallback(() => {
    if (wordsEntered > record) {
      setRecord(wordsEntered);
      localStorage.setItem('wordGameRecord', JSON.stringify(wordsEntered));
    }
  }, [wordsEntered, record]);

  const handleSurrender = useCallback(() => {
    updateRecord();
    setNextWord('Computer wins!');
    setError(null);
    setGameInProgress(false);
    setShowResultButton(true); // Enable the result button
    setGameOver(true);
    // Do not change showResultModal here
  }, [updateRecord]);

  const handleWin = () => {
    updateRecord();
    setNextWord(`${userName} won!`);
    setError(null);
    setGameInProgress(false);
    setShowResultButton(true);
    setGameOver(true);
  };

  useEffect(() => {
    if (gameInProgress && gameStarted && timeLeft > 0) {
      const timer = setInterval(() => {
        setTimeLeft(timeLeft - 1);
      }, 1000);
      return () => clearInterval(timer);
    } else if (timeLeft === 0) {
      handleSurrender();
    }
  }, [gameInProgress, gameStarted, timeLeft, handleSurrender]);

  useEffect(() => {
    if (wordSubmitted) {
      setTimeLeft(30);
      setWordSubmitted(false);
    }
  }, [wordSubmitted]);

  const validateWord = async (wordToValidate) => {
    try {
      const response = await axios.post('https://apiwordgame.vercel.app/validate-word', { word: wordToValidate });
      return response.data.valid;
    } catch (error) {
      setError('Error validating word');
      return false;
    }
  };

  const fetchNextWordFromAI = async (userWord, attempts = 0) => {
    const lastLetter = userWord[userWord.length - 1];
    if (attempts > 3) {
      setNextWord('You Won!');
      setGameInProgress(false);
      setGameOver(true);
      return;
    }

    try {
      const response = await axios.post('https://apiwordgame.vercel.app/generate-word', {
        lastLetter: lastLetter,
      });

      const newWord = response.data.word.toLowerCase();
      if (newWord && !usedWords.includes(newWord) && newWord !== userWord.toLowerCase()) {
        setNextWord(newWord.charAt(0).toUpperCase() + newWord.slice(1));
        setUsedWords([...usedWords, userWord.toLowerCase(), newWord]);
        setWordsEntered(wordsEntered + 1);
      } else {
        fetchNextWordFromAI(userWord, attempts + 1);
      }
      setWord('');
    } catch (error) {
      if (error.response && error.response.status === 429) {
        setTimeout(() => fetchNextWordFromAI(userWord, attempts + 1), Math.pow(2, attempts) * 1000);
      } else {
        setError('Error fetching next word from AI');
      }
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (nextWord && nextWord !== `${userName} won!` && nextWord !== 'Computer wins!') {
      const lastLetter = nextWord[nextWord.length - 1].toLowerCase();
      if (word[0].toLowerCase() !== lastLetter) {
        setError(`The word should start with "${lastLetter.toUpperCase()}"`);
        return;
      }
    }
    if (usedWords.includes(word.toLowerCase())) {
      setError('This word has already been used.');
      return;
    }

    const isValid = await validateWord(word);
    if (!isValid) {
      setError('Invalid word');
      return;
    }

    setError(null);
    setWordSubmitted(true);
    if (!gameStarted) {
      setGameStarted(true);
    }
    if (!positionUp) {
      setPositionUp(true);
    }

    fetchNextWordFromAI(word);
  };

  const handleReset = () => {
    updateRecord();
    setWord('');
    setNextWord('');
    setError(null);
    setUsedWords([]);
    setGameInProgress(true);
    setWordSubmitted(false);
    setTimeLeft(30);
    setGameStarted(false);
    setWordsEntered(0);
    setPositionUp(false);
    setShowResultButton(false);
    setShowResultModal(false); // Ensure modal is hidden on reset
    setGameOver(false);
  };

  const handleSeeResult = async () => {
    setError(null);
    const lastLetter = usedWords[usedWords.length - 1].slice(-1).toLowerCase();
    try {
      const response = await axios.post('https://apiwordgame.vercel.app/generate-word', {
        lastLetter: lastLetter,
      });
      const { data } = response;

      if (data && data.word) {
        setResultWords([data.word]);
      } else {
        setResultWords([]);
      }
      setShowResultModal(true); // Show the modal when the button is clicked
    } catch (error) {
      setError('Error fetching result words');
    }
  };

  const handleWordChange = (e) => {
    setWord(e.target.value);
    if (error) {
      setError(null);
    }
  };

  const handleWordClick = async (word) => {
    try {
      const response = await axios.post('https://apiwordgame.vercel.app/validate-word', {
        word,
      });
      const { englishDefinition, vietnameseDefinition } = response.data;

      setEnglishDefinition(englishDefinition);
      setVietnameseDefinition(vietnameseDefinition);
      setSelectedWord(word);
      setDefinition(englishDefinition);
      setShowDefinitionModal(true);
    } catch (error) {
      setDefinition('No definition found.');
      setShowDefinitionModal(true);
    }
  };

  const handleSpeak = (text) => {
    const speech = new SpeechSynthesisUtterance(text);
    window.speechSynthesis.speak(speech);
  };

  const handleVoiceInput = () => {
    const recognition = new (window.SpeechRecognition || window.webkitSpeechRecognition)();
    recognition.lang = 'en-US';
    recognition.start();

    recognition.onresult = (event) => {
      const spokenWord = event.results[0][0].transcript;
      setWord(spokenWord);
    };

    recognition.onerror = (event) => {
      setError('Error with voice input: ' + event.error);
    };
  };

  const toggleLanguage = () => {
    setLanguage((prevLanguage) => (prevLanguage === 'en' ? 'vi' : 'en'));
    setDefinition(language === 'en' ? vietnameseDefinition : englishDefinition);
  };

  const handleModalClose = () => {
    setShowModal(false);  // Close the modal when "I Understand" is clicked
  };

  return (
    <div className="game-page">
      {showModal ? (
        <NW_IntroductionModal onClose={handleModalClose} />
      ) : (
        <>
          <Navbar />
          <div className="stats-container">
            <UserStats userName={userName} wordsEntered={wordsEntered} record={record} />
          </div>
          <div className={`game-container ${positionUp ? 'moved-up' : ''}`}>
            <form onSubmit={handleSubmit} className="game-form">
              <h2 className="lets-play">Let's Play</h2>
              <div className="timer-container">
                {gameStarted && gameInProgress && nextWord !== `${userName} won!` && nextWord !== 'Computer wins!' && (
                  <svg className="timer-svg" viewBox="0 0 36 36">
                    <path
                      className="timer-bg"
                      d="M18 2.0845
                          a 15.9155 15.9155 0 0 1 0 31.831
                          a 15.9155 15.9155 0 0 1 0 -31.831"
                    />
                    <path
                      className="timer-fg"
                      strokeDasharray={`${(timeLeft / 30) * 100}, 100`}
                      d="M18 2.0845
                          a 15.9155 15.9155 0 0 1 0 31.831
                          a 15.9155 15.9155 0 0 1 0 -31.831"
                    />
                    <text x="18" y="20.35" className="timer-text" textAnchor="middle" dy=".3em">
                      {timeLeft}s
                    </text>
                  </svg>
                )}
              </div>
              {nextWord && nextWord !== `${userName} won!` && nextWord !== 'Computer wins!' && (
  <h2 className="next-word-container">
    <span>Next word: {nextWord}</span>
    <FaVolumeUp onClick={() => handleSpeak(nextWord)} className="speaker-icon-nw" />
  </h2>
)}
              {(!nextWord || nextWord === `${userName} won!` || nextWord === 'Computer wins!') && <h2>{nextWord}</h2>}
              <div className="input-container">
                <input
                  type="text"
                  value={word}
                  onChange={handleWordChange}
                  placeholder="Enter the word"
                  disabled={!gameInProgress}
                />
                <FaMicrophone onClick={handleVoiceInput} className="microphone-icon" />
              </div>
              {error && <p className="error-message">{error}</p>}
              <div className="button-group">
                <button type="submit" disabled={!gameInProgress}>
                  Submit
                </button>
                {gameStarted && gameInProgress && nextWord !== `${userName} won!` && nextWord !== 'Computer wins!' && (
                  <button type="button" onClick={handleSurrender}>
                    Surrender
                  </button>
                )}
                {(!gameInProgress || gameOver) && (
                  <button type="button" onClick={handleReset}>
                    Reset
                  </button>
                )}
              </div>
              {gameOver && (
                <div className="button-group">
                  {showResultButton && nextWord !== `${userName} won!` && (
                    <button className="result-button" onClick={handleSeeResult}>
                      See result
                    </button>
                  )}
                </div>
              )}
            </form>

            {usedWords.length > 0 && (
              <div className="used-words-section-nw">
                <h2>Used Words</h2>
                <p className="tip-text">
                  <em>Tip: Click on the word to see the definition.</em>
                </p>
                <div className="words-grid">
                  <ul>
                    {usedWords.map((word, index) => (
                      <li key={index} onClick={() => handleWordClick(word)}>
                        {word.charAt(0).toUpperCase() + word.slice(1)}
                      </li>
                    ))}
                  </ul>
                </div>
              </div>
            )}
          </div>
          <Footer />
          <ResultModal show={showResultModal} onClose={() => setShowResultModal(false)} words={resultWords} />
          <DefinitionModal
            show={showDefinitionModal}
            onClose={() => setShowDefinitionModal(false)}
            definition={definition}
            selectedWord={selectedWord}
            language={language}
            toggleLanguage={toggleLanguage}
          />
        </>
      )}
    </div>
  );
};

export default GamePage;
